.box {
    margin-left: 200px;
}

.row {
    display: flex;
}

.column {
    flex: 50%;
}
