@font-face {
    font-family: 'Gotham';
    src: local('GothamBook'),
    url('/src/fonts/gotham/GothamBook.ttf') format('truetype');
}

@font-face {
    font-family: 'Gotham Italic';
    src: local('GothamBookItalic'),
    url('/src/fonts/gotham/GothamBookItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Gotham Bold';
    src: local('GothamBold'),
    url('/src/fonts/gotham/GothamBold.ttf') format('truetype');
}

.mainBox {
    margin-left: 200px;
}

.row {
    display: flex;
}

.column {
    flex: 50%;
}

.buttonBox {
    margin-top: 10px;
}
