.row {
    display: flex;
    margin-left: 200px;
}

.column {
    flex: 25%;
    padding: 10px;
    text-align: center;
}

.subColumn {
    display: flex;
    justify-content: space-around;
}

.innerColumn {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
}