#image {
    background-image: url('/public/images/backgroundSetta.png');
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    top: 0px;
    left: 0px;
}

#boxLogin {
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin-right: 8%;
    margin-top: 8%;
}

#loginCard {
    background-color: rgba(217, 217, 217, 0.25);
    padding: 61px 52px;
    border-radius: 30px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

#boxLogo {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding-bottom: 40px;
}

#logoSetta {
    background-image: url('/public/images/logoSetta.png');
    width: 200px;
    height: 50px;
    background-repeat: no-repeat;
}

.boxLabel {
    width: 100%;
    text-align: start;
    padding-bottom: 32px;
}

#emailLabel, #senhaLabel {
    font-family: 'Gotham Bold';
    color: #13154F;
    font-size: 18px;
    line-height: 16.27px;
}

#submitLogin {
    border-radius: 30px;
    background-color: #FFA300;
    width: 100%;
    color: #13154F;
    font-weight: 700;
    text-transform: none;
    font-family: 'Gotham Bold';
    font-size: 17px
}
